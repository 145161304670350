import React, { useContext, useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import {
  FieldLabel,
  Form,
  FormControl,
  FieldError,
  FieldSelect,
  FieldTextArea,
  FieldInput,
  DropzoneInput,
} from "../../components/Input";
import { useDispatch, useSelector } from "react-redux";
import {
  addAsset,
  addAssetLayoutImage,
  fetchAssets,
  fetchNotTopAssetPrototypes,
  fetchTopAssetPrototypes,
} from "../../redux";
import Modal from "../../components/Modal";
import {
  getInitialState,
  PrototypeForm,
  updateAssetProperties,
} from "../../components/PrototypeForm";
import { I18nContext } from "../../i18n/I18nContext";
import DroppedFile from "../../components/DroppedFile";
import styles from "./AddAssetModal.module.scss";

const basicValues = {
  assetNameUnique: "",
  assetDescUnique: "",
  prototypeId: "",
  layout: [],
};

const maxAttachmentsSize = 209715200; //200MB

const AddAssetModal = ({ parentId, onClose, isOpen, isBuilding, assets }) => {
  const { t } = useContext(I18nContext);
  const dispatch = useDispatch();
  const currentValues = useRef(basicValues);
  const [prototype, setPrototype] = useState();
  const [initialValues, setInitialValues] = useState(basicValues);
  const [attachments, setAttachments] = useState([]);
  const [assetProperties, setAssetProperties] = useState({
    properties: [],
    procedures: [],
  });

  const topPrototypes = useSelector((state) => state.prototypes.top).filter(
    (proto) => !proto.deprecated
  );
  const notTopPrototypes = useSelector(
    (state) => state.prototypes.notTop
  ).filter((proto) => !proto.deprecated);

  const loading = useSelector((state) => state.assets.loading);

  const prototypes = isBuilding ? topPrototypes : notTopPrototypes;

  useEffect(() => {
    currentValues.current = basicValues;
    setInitialValues(basicValues);
  }, []);

  useEffect(() => {
    if (!prototype) {
      currentValues.current = basicValues;
      setInitialValues(basicValues);
      return;
    }
    currentValues.current = {
      ...currentValues.current,
      prototypeId: prototype.id,
      assetDescUnique: prototype.desc,
      tags: prototype.tags,
      // licenseId: currentValues.licenseId,
    };
    setInitialValues(
      getInitialState(
        prototype?.assetProperties.properties,
        currentValues.current
      )
    );
    setTimeout(() => {
      setAssetProperties(prototype?.assetProperties);
    }, 100);
  }, [prototype, currentValues.name]);

  useEffect(() => {
    setAttachments(prototype?.attachments ?? []);
  }, [prototype]);

  const handleDeleteFile = (fileToRemove) => {
    setAttachments(attachments.filter((file) => file !== fileToRemove));
  };

  const handleClose = () => {
    setAssetProperties({
      properties: [],
      procedures: [],
    });
    setAttachments([]);
    setPrototype(null);
    onClose();
  };

  const createValidationSchema = (t, assetProperties) => {
    const propertiesSchema = assetProperties.properties.reduce(
      (current, property) => {
        if (property.flags.isMandatory) {
          current[property.id || property.name] = Yup.mixed().required(
            t("this field is required, please provide a value.")
          );
        }
        return current;
      },
      {}
    );

    return Yup.object({
      prototypeId: Yup.string().required(t("please select one of the options")),
      assetNameUnique: Yup.string()
        .notOneOf(assets?.length ? assets : [], t("name must be unique"))
        .required(t("please enter the name of the asset")),
      layout: Yup.array().when("prototypeId", {
        is: (prototypeId) =>
          !!prototypeId &&
          prototypes.find((x) => x.id === prototypeId).grouping,
        then: Yup.array().required(t("please add layout image")),
      }),
      ...propertiesSchema,
    });
  };

  const handleSubmit = async (values, { resetForm }) => {
    const {
      prototypeId,
      assetNameUnique,
      assetDescUnique,
      ...propertiesValues
    } = values;

    const updatedAssetProperties = updateAssetProperties(
      assetProperties.properties,
      propertiesValues
    );
    try {
      const { data } = await dispatch(
        addAsset(t, parentId, prototypeId, {
          assetProperties: updatedAssetProperties,
          name: assetNameUnique,
          desc: assetDescUnique,
          attachments: attachments.map((attach) => {
            if (typeof attach === "string") {
              return attach;
            } else {
              return attach.name;
            }
          }),
        })
      );

      if (values.layout && values.layout.length) {
        const response = await dispatch(
          addAssetLayoutImage(t, data.id, values.layout[0])
        );
        if (response) {
          await dispatch(fetchAssets(parentId));
          await dispatch(fetchAssets());
        }
      }

      await dispatch(fetchAssets(parentId));
      if (isBuilding) {
        dispatch(fetchTopAssetPrototypes());
      } else {
        dispatch(fetchNotTopAssetPrototypes());
      }
      handleClose();
    } catch (err) {
      if (!parentId || (!parentId && err.response.status !== 406)) {
        dispatch(fetchTopAssetPrototypes());
        handleClose();
      }
    }
  };

  useEffect(() => {
    setInitialValues(getInitialState(assetProperties, currentValues.current));
  }, [assetProperties]);

  return (
    <Modal
      className={styles.modal}
      isOpen={isOpen}
      onClose={handleClose}
      title={t("add asset", "title")}
      form="add-asset-form"
      pending={loading}
    >
      <Form
        id="add-asset-form"
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={createValidationSchema(t, assetProperties)}
        enableReinitialize={true}
      >
        {({ values, errors }) => {
          currentValues.current = values;

          return (
            <>
              <FormControl>
                <FieldLabel>
                  {t("prototype")}
                  <span className={styles.red}> *</span>
                </FieldLabel>
                <div className={styles.width}>
                  <FieldSelect
                    size="s"
                    name="prototypeId"
                    onChange={(prototypeId) =>
                      setPrototype(prototypes.find((p) => p.id === prototypeId))
                    }
                    options={prototypes
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map((proto) => ({
                        label: proto.name,
                        value: proto.id,
                        global: proto.visibleOnAllCompanies,
                      }))}
                    placeholder={t("prototype")}
                  />
                </div>
                <FieldError name="prototypeId" />
              </FormControl>
              {prototype && (
                <FormControl>
                  <FieldLabel>
                    {prototype.grouping ? t("name") : t("id", "upper")}
                    <span className={styles.red}> *</span>
                  </FieldLabel>
                  <FieldInput
                    name="assetNameUnique"
                    type="text"
                    placeholder={
                      prototype.grouping
                        ? t("add asset name")
                        : t("add asset id")
                    }
                    size="m"
                  />
                  <FieldError name="assetNameUnique" />
                </FormControl>
              )}
              {prototype && prototype.grouping && (
                <div className={styles.margin_bottom}>
                  <FieldLabel>{t("asset layout image")}</FieldLabel>
                  <DropzoneInput
                    maxSize={maxAttachmentsSize}
                    name="layout"
                    text={t("drag and drop an image to upload, or")}
                    notMultiple
                  />
                </div>
              )}
              {prototype && (
                <FormControl>
                  <FieldLabel>
                    {t("description")}
                    <span className={styles.red}> *</span>
                  </FieldLabel>
                  <FieldTextArea name="assetDescUnique" disabled />
                  <FieldError name="assetDescUnique" />
                </FormControl>
              )}
              <div className={styles.separator}></div>

              {prototype && (
                <PrototypeForm
                  isCreating={false}
                  assetProperties={assetProperties.properties}
                  editable={true}
                  noDefaultDateValue={true}
                  notDraggable={true}
                />
              )}
            </>
          );
        }}
      </Form>
      <div className={styles.start_row}>
        {attachments.map((file, index) => (
          <DroppedFile file={file} key={index} onDelete={handleDeleteFile} />
        ))}
      </div>
    </Modal>
  );
};

export default AddAssetModal;
