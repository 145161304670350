import React, { useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";

import Icon from "../../components/Icon";
import Entry from "../../layouts/Entry";

import { useSelector, useDispatch } from "react-redux";
import styles from "./RequestEmailChange.module.scss";
import { confirmEmailChange } from "../../redux";
import { I18nContext } from "../../i18n/I18nContext";

const useToken = () => {
  const { pathname } = useLocation();
  const token = pathname.split("/").pop();

  return token;
};

const ConfirmEmailChange = () => {
  const { t } = useContext(I18nContext);
  const dispatch = useDispatch();
  const token = useToken();

  const hasError = useSelector(
    (state) => state.auth.showConfirmEmailChangeError
  );

  useEffect(() => {
    submitEmailChangeConfirmation();
  }, []);

  const submitEmailChangeConfirmation = async () => {
    dispatch(confirmEmailChange(token));
  };

  return (
    <Entry>
      <div className={styles.container}>
        {!hasError ? (
          <span className={styles.icon_circle}>
            <Icon className={styles.icon} name="envelope" />
          </span>
        ) : (
          <span className={styles.icon_circleRed}>
            <Icon className={styles.icon} name="warning" />
          </span>
        )}
        {!hasError ? (
          <div className={styles.info}>
            <div className={styles.header}>{t("email changed")}</div>
            <div className={styles.txt_container}>
              {t("your email address has been changed successfully")}.
            </div>
          </div>
        ) : (
          <div className={styles.error}>
            <div className={styles.header}>{t("email change error")}</div>
            <div className={styles.txt_container_no_margin_bottom}>
              {t("there has been an error processing email change")}.
            </div>
            <div className={styles.txt_container}>
              {t("please try again or contact an administrator")}.
            </div>
          </div>
        )}
      </div>
    </Entry>
  );
};

export default ConfirmEmailChange;
