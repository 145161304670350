import React, { useContext, useState } from "react";
import styles from "./TaskHistory.module.scss";
import { useSelector } from "react-redux";
import { I18nContext } from "../../i18n/I18nContext";
import Icon from "../Icon";
import moment from "moment-timezone";
import { setUTCDate } from "../../helpers/helpers";

const TaskHistory = ({ created, createdBy, history }) => {
  const { t } = useContext(I18nContext);
  const { profile } = useSelector((state) => state.profile);
  const [historyOpened, setHistoryOpened] = useState(false);

  const statusToText = (status) => {
    switch (status) {
      case "COMPLETE":
        return "done";
      case "REOPEN":
        return "undo";
      case "CANCEL":
        return "cancel";
      case "UNDOABLE":
        return "cannot be done";
      default:
        return status;
    }
  };

  return (
    <div className={styles.task_history}>
      <div
        className={styles.task_history_header}
        onClick={() => setHistoryOpened(!historyOpened)}
      >
        <div className={styles.task_history_header_title}>
          {t("task history")}
        </div>
        <div className={styles.task_history_header_icon}>
          {historyOpened ? (
            <Icon name="chevron-down" className={styles.task_history_icon_up} />
          ) : (
            <Icon name="chevron-down" />
          )}
        </div>
      </div>
      {!!historyOpened && (
        <div className={styles.task_history_events_list}>
          <div className={styles.task_history_event}>
            {`${t("create", "upper")} - `}
            {createdBy}
            {" - "}
            {new Date(created).toLocaleDateString(undefined, {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
            })}{" "}
            {new Date(created).toLocaleTimeString(undefined, {
              hour12: false,
            })}
          </div>
          {history.map((historyEvent, index) => (
            <div key={index} className={styles.task_history_event}>
              {t(statusToText(historyEvent.type), "upper")}
              {" - "}
              {historyEvent.user.userName
                ? historyEvent.user.userName
                : t("status changed by system")}
              {historyEvent.user.userName &&
              historyEvent.reason &&
              historyEvent.reason !== "''"
                ? `: ${historyEvent.reason}`
                : ""}
              {" - "}
              {new Date(historyEvent.occurredAt).toLocaleDateString(undefined, {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              })}{" "}
              {new Date(historyEvent.occurredAt).toLocaleTimeString(undefined, {
                hour12: false,
              })}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default TaskHistory;
