import React, { useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";

import Icon from "../../components/Icon";
import Entry from "../../layouts/Entry";

import { useSelector, useDispatch } from "react-redux";
import styles from "./RequestActivation.module.scss";
import { requestAccountActivation } from "../../redux";
import { I18nContext } from "../../i18n/I18nContext";

const useToken = () => {
  const { pathname } = useLocation();
  const token = pathname.split("/").pop();

  return token;
};

const RequestActivation = () => {
  const { t } = useContext(I18nContext);
  const dispatch = useDispatch();
  const token = useToken();

  const hasError = useSelector(
    (state) => state.auth.showRequestActivationError
  );

  useEffect(() => {
    submitActivationRequest();
  }, []);

  const submitActivationRequest = async () => {
    dispatch(requestAccountActivation(token));
  };

  return (
    <Entry>
      <div className={styles.container}>
        {!hasError ? (
          <span className={styles.icon_circle}>
            <Icon className={styles.icon} name="padlock" />
          </span>
        ) : (
          <span className={styles.icon_circleRed}>
            <Icon className={styles.icon} name="warning" />
          </span>
        )}
        {!hasError ? (
          <div className={styles.info}>
            <div className={styles.header}>
              {t("account activation requested")}
            </div>
            <div className={styles.txt_container_no_margin_bottom}>
              {t(
                "you will now receive an email with a link to set your password"
              )}
              .
            </div>
            <div className={styles.txt_container_no_margin_bottom}>
              {t("this link is valid for 15 minutes")}.
            </div>
            <div className={styles.txt_container}>
              {t(
                "if you don't click this link within 15 minutes, you need to do a new password change request to change your password"
              )}
              .
            </div>
          </div>
        ) : (
          <div className={styles.error}>
            <div className={styles.header}>
              {t("account activation request error")}
            </div>
            <div className={styles.txt_container_no_margin_bottom}>
              {t("there has been an error sending account activation request")}.
            </div>
            <div className={styles.txt_container}>
              {t("please try again or contact an administrator")}.
            </div>
          </div>
        )}
      </div>
    </Entry>
  );
};

export default RequestActivation;
